<template>
    <v-form>
        <v-text-field 
            v-model="$v.form.new_password.$model"
            :error-messages="getErrors('form.new_password')"
            autocomplete="new-password"
            label="Пароль" 
            name="new_password" 
            :disabled="disabled"
            type="password"
            placeholder=" " 
            :color="$const.color.primary"
        ></v-text-field>

        <v-text-field 
            v-model="$v.form.confirm_password.$model"
            :error-messages="getErrors('form.confirm_password', {
                compare: 'Пароль не совпадает с подтверждением'
            })"
            :disabled="disabled"
            label="Подтверждение пароля"
            name="confirm_password" 
            type="password" 
            placeholder=" " 
            :color="$const.color.primary"
        ></v-text-field>

        <v-btn
            outlined
            height="40px"
            :disabled="disabled"
            :loading="waiting.save.update" 
            class="button-stylized-blue"
            @click.prevent.stop="dialogs.update = true"
        >
            Обновить
        </v-btn>

        <confirm-action-with-pass-dialog
            v-model="dialogs.update"
            :action="save"
        />

        <error-alert
          v-if="errorMessage"
          :message="errorMessage"
          class="mt-4"
        />
    </v-form>
</template>
<script>
import { required, minLength } from 'vuelidate/lib/validators'
import UserForm from './UserForm.vue'
import ConfirmActionWithPassDialog from '@/components/template/ConfirmActionWithPassDialog.vue'
import ErrorAlert from '@/components/template/ErrorAlert.vue'

export default {
    mixins: [ _.omit(UserForm, 'created') ],
    components: { ConfirmActionWithPassDialog, ErrorAlert },
    props: {
        model: { type: Object, required: true },
        disabled: { type: Boolean, default: false }
    },
    data () {
        return {
            form: {
                new_password: '',
                confirm_password: ''
            },
            dialogs: {
                update: false
            }
        }
    },
    validations() {
        const PASSWORD_MIN_LENGTH = 6;
        return {
            form: {
                new_password: {
                    required,
                    minLength: minLength(PASSWORD_MIN_LENGTH)
                },
                confirm_password: {
                    required,
                    compare: function(value) {
                        return this.form.new_password.length > 0 ? this.form.new_password === value : true;  
                    },
                    minLength: minLength(PASSWORD_MIN_LENGTH)
                }
            }
        }
    },
    computed: {
        errorMessage () {
            if (!this.serverErrors) return null
            for (const e1 of this.serverErrors)
                for (const e2 of e1)
                    return e2

            return null
        }
    },
    methods: {
        prepareForm(form) {
            return form;
        }
    }
}
</script>