<template>
    <v-progress-linear
        v-if="loadings.adminUser"
        :color="$const.color.primary"
        indeterminate
    />
    <div v-else-if="adminUser" class="mt-5">
        <p>В этом интерфейсе Вы можете восстановить доступ к учетной записи Системного Администратора продукта ОКО ВПР, в случае его утраты.</p>
        <p>Логин Системного Администратора продукта ОКО ВПР – <strong>{{ adminUser.login }}</strong>.</p>
        <p>Для сброса пароля нажмите на кнопку «Заменить пароль», и укажите новый пароль для Системного Администратора продукта ОКО ВПР.</p>

        <v-btn
            outlined
            height="40px"
            class="button-stylized-blue"
            @click="dialogs.updateUserPassword = true"
        >Заменить пароль</v-btn>

        <custom-dialog
            v-model="dialogs.updateUserPassword"
            title="Обновить пароль"
            max-width="600px"
        >
            <update-user-password-form
                :key="dialogs.updateUserPassword"
                :model="adminUser"
                :disabled="!isCanEditUpdateAdminPasswordSection"
                @success="onUpdatePasswordSuccess"
            />
        </custom-dialog>
    </div>
    <p v-else>Не удалось получить данные с сервера</p>
</template>

<script>
import UpdateUserPasswordForm from '@/components/forms/UpdateUserPasswordForm'
import isUserCanMixin from '@/mixins/isUserCanMixin'
import CustomDialog from '@/components/template/CustomDialog.vue'

export default {
    components: { UpdateUserPasswordForm, CustomDialog },
    mixins: [ isUserCanMixin ],
    created () {
        this.fetchAdminUser()
    },
    data () {
        return {
            adminUser: null,
            loadings: {
                adminUser: false
            },
            dialogs: {
                updateUserPassword: false
            }
        }
    },
    methods: {
        async fetchAdminUser () {
            try {
                this.loadings.adminUser = true
                const { success, error, data } = await this.$store.dispatch('user/one', {
                    id: this.$store.state.user.profile.tree,
                    fields: ['name', 'role', 'login'].join(',')
                })
                
                if (!success)
                    throw new Error(error)
                
                this.adminUser = data
            } catch (e) {
                console.error(e)
            } finally {
                this.loadings.adminUser = false
            }
        },
        onUpdatePasswordSuccess () {
            this.$root.$emit('snack-bar', { text: 'Пароль успешно обновлён' })
            this.dialogs.updateUserPassword = false
        }
    }
}
</script>